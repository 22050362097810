.card-details-form {
    display: flex;
    flex-direction: column;
    min-width: 35vw;
    gap: 2rem;
    width: 35%;
    margin-left: 5%;
}

.card-details-form label {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
}

.card-details-form input {
    padding: 0 1rem;
    width: 100%;
    height: 3rem;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.13);
    outline: none;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.card-details-form button {
    min-width: 35vw;
    padding: 1rem 0;
    border-radius: 6px;
    font-size: 1.2rem;
    color: #FFFFFF;
    background-color: #220930;
    border: 1px solid black;
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.card-details-form button:hover {
    background-color: black;
}

.card-details-form input::placeholder {
    color: #BBB9BC;
    font-weight: 600;
    font-size: 1.2rem;
    transform: translateY(0.12rem);
}

.date-cvc-div {
    display: flex;
    gap: 1rem;
}

.exp-div {
    flex: 1 1 5vw;
}

.exp-date-div {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.9rem;
}

.cvc-div {
    flex: 2;
}

.error-msg-p {
    margin: 0;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    font-weight: 500;
    color: #FF0000;
}